  .pagination__counter {
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;
      clip-path: polygon(96% 0, 100% 4%, 54% 100%, 46% 100%, 0 4%, 4% 0, 50% 92%);
      width: 15px;
      height: 7.5px;
      background: #C5281C;
    }

    select {
      padding-left: 10px;
      appearance: unset;
      margin-left: 10px;
      height: 40px;
      min-width: 60px;
      border-radius: 3px;
      border-color: #CCCCCC;
    }
  }