:root {
    --primary: #C21B17;
    --primary-black: #000000;
    --secondary-black: #333333;
    --background-page-color: rgb(242, 242, 242);
    --white: #ffffff;
    // Definizione colore sfondi componente tag
    --tag-bg-color-primary: #9B1612;
    // Definizione colore sfondi componente button
    --button-bg-color-secondary-light: #C21B17;
    --button-bg-color-secondary-light-hover: #8E1230;
    --button-border-and-color-secondary-ghost-light-hover: #5A5D62;
    --button-border-and-color-secondary-ghost-light-disabled: #8C8C8C;
    --button-color-secondary-light-disabled: #CACACA;
    --button-bg-color-secondary-light-disabled: #8C8C8C;
    --transparent: transparent;
    --table-head-color: #5A5D62;
    --table-body-color: #20252B;
    --table-striped-color: #F2F2F2;
    --Color-Content-Accent: #C21B17;
    --letter-counter-title-color: #C5281C;
    --letter-counter-subtitle-color: #494949;
    --letter-counter-count-color: #333333;
    --link-button-primary-hover: #5A5D62;

}