@use "/src/assets/scss/index" as *;
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body {
  width: 100%;
  height: 100%;
  background-color: var(--background-page-color);
}

* {
  font-family: 'Roboto';
}

p {
    margin: 0!important;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  h1 {
    margin: 0px;
    font-weight: 700;
    font-size: 32px;
    line-height: normal;
    color: var(--primary-black);
  }
  
  .subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    color: var(--primary-black);
  }

  .default-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 126.443%;
    color: var(--secondary-black);
  }

  .margin-bottom16 {
    margin-bottom: 16px!important;
  }

  .margin-top24 {
    margin-top: 24px;
  }

  .margin-bottom10 {
    margin-bottom: 10px!important;
  }

  .margin-right5 {
    margin-right: 5px!important;
  }

  .margin-right16 {
    margin-right: 16px;
  }

  .background-white {
    background-color: var(--white);
  }

  .margin-top60 {
    margin-top: 60px;
  }

  .padding-top16 {
    padding-top: 16px!important;
  }

  .margin-top20 {
    margin-top: 20px;
  }
  
  .margin-top16 {
    margin-top: 16px!important;
  }

  .margin-bottom16 {
    margin-bottom: 16px!important;
  }
  
  .margin-right4 {
    margin-right: 4px !important;
  }

  .cursor-default {
    cursor: default!important;
  }

  .margin-bottom15 {
    margin-bottom: 15px;
  }